<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-row>
      <b-col>
        <b-card>
          <!-- header -->
          <b-row
            align-v="center"
            class="mb-2 pb-1"
            style="border-bottom: 1px solid #ddd"
          >
            <b-col
              class="text-center"
              style="cursor: pointer"
              cols="auto"
              @click="$router.go(-1)"
            >
              <b-col cols="12">
                {{ $t("back") }}
              </b-col>
              <b-col cols="12">
                <feather-icon
                  v-if="$i18n.isRTL"
                  icon="ArrowRightIcon"
                  size="14"
                />
                <feather-icon
                  v-else
                  icon="ArrowLeftIcon"
                  size="14"
                />
              </b-col>
            </b-col>
            <b-col class="leftBorder">
              <b-row
                align-h="between"
                align-v="center"
              >
                <b-col cols="auto">
                  <h3>{{ ticketData.title }}</h3>
                  <span>{{ ticketData.category_title }}</span>
                </b-col>
                <b-col
                  cols="auto"
                  class="text-right"
                >
                  <h6>{{ this.$t("last_update") }}</h6>
                  <span>
                    {{
                      new Date(ticketData.updated_at).toLocaleString(
                        $i18n.locale
                      )
                    }}
                  </span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <!-- tickets -->
          <b-row align-h="center">
            <b-col
              cols="12"
              xl="11"
            >
              <TicketItem
                :ticket-data="{
                  answer_by: 'user',
                  created_at: ticketData.created_at,
                  context: ticketData.context,
                  file: ticketData.file,
                }"
              />
              <div
                v-if="(ticketData.answers || []).length !== 0"
                class="mb-3 py-0"
              >
                <TicketItem
                  v-for="item in ticketData.answers"
                  :key="item.id"
                  :ticket-data="item"
                />
              </div>

              <b-row
                v-if="ticketData.status_value === 'open' && ticketData.read_by === 'operator'"
                class="shadow px-2 py-2 rounded-lg"
                align-h="between"
                align-v="center"
              >
                <b-col
                  cols="auto"
                  class="py-2 py-sm-0"
                >
                  <h4>{{ $t('close_ticket_description') }}</h4>
                  <span class="text-danger">{{ $t('close_ticket_automatically') }}</span>
                </b-col>
                <b-col cols="auto">
                  <b-button
                    variant="danger"
                    class="flex-end"
                    inline
                    @click="closeTicket"
                  >
                    <span v-if="!closeLoading">
                      {{ $t("close_ticket") }}</span>
                    <b-spinner
                      v-else
                      small
                      variant="light"
                      type="grow"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <b-row
                v-if="ticketData.status_id === 2"
              >
                <b-col
                  cols="12"
                >
                  <b-alert
                    :show="true"
                    variant="danger"
                    class="p-2"
                  >
                    {{ $t('closed_ticket_message') }}
                  </b-alert>
                </b-col>
              </b-row>

              <!--  -->
              <ValidationObserver
                v-if="ticketData.status_id !== 2"
                ref="form"
                v-slot="{ invalid }"
              >
                <b-form
                  class="mt-5"
                  @submit.prevent="onSubmitAnswer"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('answer')"
                    rules="required"
                  >
                    <b-form-group
                      id="answer"
                      label-for="answer"
                    >
                      <b-form-textarea
                        id="answer"
                        v-model="form.answer"
                        :placeholder="$t('answer')"
                        rows="8"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </b-form-group>
                  </validation-provider>
                  <b-row
                    align-v="center"
                    align-h="between"
                  >
                    <b-col
                      cols="12"
                      sm="auto"
                    >
                      <ValidationProvider
                        v-slot="{ errors, validate }"
                        :name="$t('image')"
                        :rules="'image|size:' + MAX_IMAGE_UPLOAD_SIZE"
                      >
                        <div
                          class="d-flex flex-row-reverse"
                        >
                          <feather-icon
                            v-if="form.file"
                            class="text-danger stroke-current"
                            icon="XIcon"
                            size="28"
                            variant="danger"
                            @click="form.file = null"
                          />
                          <b-form-file
                            v-model="form.file"
                            plain
                            accept="image/png, image/jpg, image/jpeg"
                            @change="validate"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="auto"
                      class="mt-1 mt-sm-0"
                    >
                      <b-button
                        type="submit"
                        variant="primary"
                        class="flex-end"
                        block
                        :disabled="invalid"
                      >
                        <span v-if="!isLoading">
                          {{ $t("createTicketPage.form.btn") }}</span>
                        <b-spinner
                          v-else
                          small
                          variant="light"
                          type="grow"
                        />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </ValidationObserver>
              <!--  -->
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BCard,
  BButton,
  BForm,
  BFormGroup,
  BFormFile,
  BSpinner,
  BFormTextarea,
} from 'bootstrap-vue'
import { required, image, size } from '@validations'
import TicketService from '@/services/apis/tickets'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import TicketItem from '@/views/components/ticket/ticketItem.vue'
import { resizeImage } from '@/utils'

const { getTicketsById, sendTicketAnswer, closeTicketById } = new TicketService()

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormFile,
    BSpinner,
    TicketItem,
    FeatherIcon,
  },
  data() {
    return {
      show: true,
      isLoading: false,
      closeLoading: false,
      required,
      image,
      size,
      ticketData: {},
      form: {
        answer: '',
        file: null,
      },
    }
  },
  computed: {
    MAX_IMAGE_UPLOAD_SIZE() {
      return process.env.VUE_APP_MAX_IMAGE_UPLOAD_SIZE
    },
  },

  watch: {
    // ticketData(newVal) {
    //   if ((newVal.read_by === 'user' && newVal.operator_read === '0') || (newVal.read_by === 'operator' && newVal.user_read === '0')) {
    //     if (newVal.answers.length === 0) {
    //       // eslint-disable-next-line no-param-reassign
    //       newVal.icon_read = 0
    //     } else {
    //       // eslint-disable-next-line no-param-reassign
    //       newVal.icon_read = 1
    //       newVal.answers.forEach((item, index) => {
    //         if (index === newVal.answers.length - 1) {
    //           // eslint-disable-next-line no-param-reassign
    //           item.icon_read = 0
    //         } else {
    //           // eslint-disable-next-line no-param-reassign
    //           item.icon_read = 1
    //         }
    //       })
    //     }
    //   } else {
    //     // eslint-disable-next-line no-param-reassign
    //     newVal.icon_read = 1
    //     if (newVal.answers.length !== 0) {
    //       newVal.answers.forEach(item => {
    //         // eslint-disable-next-line no-param-reassign
    //         item.icon_read = 1
    //       })
    //     }
    //   }
    // },
  },
  mounted() {
    this.getTicketsById()
  },

  methods: {
    async onSubmitAnswer() {
      this.isLoading = true

      const compressedFile = this.form.file ? await resizeImage(this.form.file) : null
      const answerTicket = {
        context: this.form.answer,
        file: compressedFile,
      }

      sendTicketAnswer({ id: this.ticketData.id, answerTicket })
        .then(({ data }) => {
          this.getTicketsById()
          this.$swal({
            title: data?.message,
            icon: 'success',
            timer: 2500,
            showConfirmButton: false,
          })
          this.form.answer = ''
          this.form.file = null
          this.$refs.form.reset()
          this.$router.push({ name: 'tickets' })
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    getTicketsById() {
      getTicketsById(this.$route.params.id)
        .then(({ data }) => {
          this.ticketData = data.results
        })
        .finally(() => {
          this.show = false
        })
    },

    closeTicket() {
      this.closeLoading = true
      closeTicketById(this.ticketData.id)
        .then(({ data }) => {
          this.$router.push({ name: 'tickets' })
          this.$swal({
            title: data?.message,
            icon: 'success',
            timer: 2500,
            showConfirmButton: false,
          })
        })
        .finally(() => {
          this.closeLoading = false
        })
    },
  },
}
</script>
<style scoped>
.leftBorder {
  border-left: 1px solid #ddd;
}

.rightBorder {
  border-right: 1px solid #ddd;
}

.user-logo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: rgb(225, 225, 225);
}
</style>
